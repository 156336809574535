$c: '.c-link-block';

#{$c} {
  h2 {
    margin-bottom: 20px;
  }
  &__wrapper {
    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      gap: 50px 50px;
    }
  }
  &__text-wrapper {
    @include media-breakpoint-up(lg) {
      grid-area: 1 / 1 / 2 / 2;
      max-width: none;
    }
  }
  &__links-wrapper {
    margin-bottom: 20px;
    @include media-breakpoint-up(lg) {
      grid-area: 1 / 2 / 3 / 3;
      max-width: none;
      margin-bottom: 0;
    }
  }
  &__button-wrapper {
    @include media-breakpoint-up(lg) {
      grid-area: 2 / 1 / 3 / 2;
      max-width: none;
    }
  }
  &__text {
    color: $tuna-gray-60;
  }
  &__link {
    clear: both;
    margin-bottom: 12px;
    padding: 0.375rem 0;
  }
}
