.c-double-quote {
  &__box {
    &-inner {
      box-shadow: 0px 4px 12px rgb(15 17 21 / 10%);
    }
  }
  &__person {
    &-name {
      color: $tuna-gray-80;
      font-size: 1.25rem;
    }
    &-title {
      color: $tuna-gray-60;
      font-size: 1.25rem;
      &.withButton {
        margin-bottom: 1.5rem;
      }
    }
  }
  &__text {
    color: $tuna-gray-80;
    font-size: 1.25rem;
    font-family: $headings-font-family;
    &::before {
      content: '“';
      color: $slate-gray-40;
      font-size: 2rem;
      position: absolute;
      left: -1rem;
    }
    &::after {
      content: '”';
      color: $slate-gray-40;
      font-size: 2rem;
      position: absolute;
      margin-top: 0.55rem;
      margin-left: 0.5rem;
    }
  }
}
