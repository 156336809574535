.candidate-detail {
  position: relative;
  display: grid;
  grid-gap: 40px;
  grid-template-areas:
    'summary'
    'intro'
    'skills'
    'languages'
    'cta'
    'divider';

  & section {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }

  &__summary {
    grid-area: summary;

    &--job-title {
      color: $tuna-gray-80;
      font-size: 32px;
      line-height: 32px;
    }
  }

  &__intro {
    grid-area: intro;
    color: $tuna-gray;
    font-size: 18px;

    &--title {
      font-size: 32px;
      line-height: 1;
      margin-bottom: 18px;
    }
  }

  &__skills {
    grid-area: skills;

    &--title {
      font-size: 32px;
      line-height: 1;
      margin-bottom: 18px;
    }
  }

  &__languages {
    grid-area: languages;

    &--title {
      font-size: 32px;
      line-height: 1;
      margin-bottom: 18px;
    }
  }

  &__iframeForm {
    padding-inline: 1.5625rem;
    padding-block: 3rem;
    @include media-breakpoint-up(xl) {
      padding-inline: 5.9375rem 0;
    }
  }
  &__iframe {
    flex: 1;
  }

  &__image {
    @include media-breakpoint-up(xl) {
      flex: 1;
    }
    > span {
      height: 100% !important;
      width: 100% !important;
    }
  }

  &__cta {
    grid-area: cta;
    position: sticky;
    bottom: 20px;
    margin-block: 20px;
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(xl) {
      margin: 0;
      position: static;
      align-self: end;
    }
  }

  &__divider {
    grid-area: divider;
    width: 100%;
    margin: 0 0 12px 0;

    @include media-breakpoint-up(xl) {
      margin: 12px 0;
    }
  }

  @include media-breakpoint-up(xl) {
    grid-template-areas:
      'summary cta'
      'divider divider'
      'intro skills'
      'intro languages'
      'intro .';
    grid-template-columns: 1fr minmax(auto, 424px);
    grid-gap: 48px 136px;
  }
}
