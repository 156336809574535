.summary-wrapper {
  background-color: $slate-gray-10;

  &__item {
    &--title {
      font-size: 12px;
      color: $slate-gray;
    }

    &--value {
      font-size: 18px;
      line-height: 32px;
      color: $tuna-gray;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 20px;
      border-top: 0;
      border-bottom: 0 !important;

      &:not(:last-child) {
        border-right: 1px solid $slate-gray-20;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    width: fit-content;
  }
}
