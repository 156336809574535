form {
  .form-control {
    border-radius: 4px;
    border: 1px solid #c8cdd9;
    padding: 12px 16px;
    font-size: 14px;
    height: inherit;
    &::placeholder {
      color: $tuna-gray-40;
    }
  }

  button[type='submit'] {
    min-width: 176px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    border-radius: 35.3rem;
    background-color: $orange;
    color: #fff;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.5rem;
    padding: 0.4rem 0;
  }

  .react-datepicker {
    box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08),
      0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03),
      0px 1px 3px rgba(19, 10, 46, 0.13);
    border-radius: 12px;
    border: 0;

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__navigation {
      display: none;
    }

    .react-datepicker__header {
      background: none;
    }

    .react-datepicker__current-month {
      display: none;
    }

    .react-datepicker__header__dropdown {
      padding-top: 10px;
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
      padding: 10px 14px;
      background: $white;
      border: 0;
      font-weight: bold;
      border-radius: 7px;

      & option {
        min-height: 2rem;
        padding: 0px 10px 1px;
        &::before {
          content: 'asd';
        }
      }
    }
  }

  .react-datepicker__input-container {
    input {
      cursor: pointer;
    }
    &::before {
      content: url(/calendar-icon.svg);
      position: absolute;
      cursor: pointer;
      top: 50%;
      right: 20px;
      transform: translateY(-38%);
    }
  }

  label {
    font-size: 14px;
    color: #7783a0;
  }

  .error {
    border: 1px solid $red;
    border-left: 4px solid $red;
  }
  .error-message {
    display: flex;
    align-items: center;
    color: $red;
    margin-top: 5px;
    font-size: 14px;

    &::before {
      content: 'X';
      width: 20px;
      text-align: center;
      height: 20px;
      font-size: 10px;
      padding: 3px;
      margin-right: 5px;
      border-radius: 50%;
      display: inline-block;
      color: $white;
      background: $red;
    }
  }
  .form-gap {
    gap: 24px;
  }
  .form-checkbox {
    background: $white;
    border: 1px solid $slate-gray-40;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    display: inline-block;

    &:checked {
      accent-color: $orange;
    }

    & + label p {
      margin-bottom: 0;
    }
  }
  .input-group {
    &-text {
      border-radius: 4px;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: $white;
    }
    .form-control {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .form-file-upload {
    font-size: 1.125rem;
    color: $slate-gray;
    background-color: $tuna-gray-20;
  }
}
