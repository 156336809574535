/* ------ Footer ------ */

$c: '.c-footer';

#{$c} {
  background: $purple;
  width: 95%;
  float: right;
  overflow: visible;
  margin-top: 50px;

  @include media-breakpoint-down(md) {
    padding-top: 80px;
  }

  &::before {
    content: url(/footer_spark.svg);
    position: absolute;
    top: -40px;
    right: 21%;

    @include media-breakpoint-down(md) {
      right: 2vh;
    }
  }
  &.light {
    background-color: $tuna-gray-20;
    position: relative;
    @include media-breakpoint-up(lg) {
      background-color: $white;
      &::after {
        top: -198px;
        background-image: url("data:image/svg+xml,%3Csvg width='240' height='248' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m200.347 67.254-38.314 191.184 22.219 2.697 39.161-190.876-23.066-3.005ZM143.071 145.885l16.999-12.848-67.507-74.54-17.202 13.196 67.71 74.192Z' fill='%23fff'/%3E%3Cpath d='m37.215 175.94 271.197 41.653.988-22.838-271.035-43.051-1.15 24.236Z' fill='%23fff'/%3E%3Cpath d='m200.347 67.254-38.314 191.184 22.219 2.697 39.161-190.876-23.066-3.005Z' fill='%23F8AD07' style='mix-blend-mode:multiply'/%3E%3Cpath d='m143.071 145.885 16.999-12.848-67.507-74.54-17.202 13.196 67.71 74.192Z' fill='%23870B58' style='mix-blend-mode:multiply'/%3E%3Cpath d='m37.215 175.94 271.197 41.653.988-22.838-271.035-43.051-1.15 24.236Z' fill='%23E4003A' style='mix-blend-mode:multiply'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' transform='rotate(56.801 90.98 168.26)' d='M0 0h251.315v217.455H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        width: 240px;
        height: 248px;
        content: '';
        position: absolute;
        right: 0;
        pointer-events: none;
      }
    }
    & #{$c}__container {
      background-color: $tuna-gray-20;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-up(lg) {
        background-color: $white;
      }
      .row div:first-child {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
  }
  &__container {
    box-shadow: inset 0px 1px 0px #555d71;
    background-color: $slate-gray-10;

    @include media-breakpoint-up(lg) {
      padding: 1.5rem 6.375rem !important;
      background-color: $slate-gray-10;
    }
  }

  &__brand {
    color: $tuna-gray-60;
    font-size: 14px;
    line-height: 24px;
  }

  &__nav {
    flex-grow: 1;

    a {
      font-size: 14px;
      line-height: 24px;
      color: $tuna-gray-80;
      text-decoration: underline;
    }

    &-items {
      list-style: none;
      column-gap: 24px;
    }
  }
}
