.c-hero {
  border-top: 1px solid $slate-gray-20;
  &__button {
    display: flex;
    align-items: center;
    position: relative;
    &::after {
      content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 -3 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.5148 3.51465L22.0002 11.9999L13.5149 20.4852L12.1007 19.071L18.1718 12.9999H2V10.9999H18.1717L12.1006 4.92886L13.5148 3.51465Z' fill='%23F9D2C3'/%3E%3C/svg%3E");
      display: inline-block;
      margin-left: 15px;
    }
  }
  &__intro {
    padding-top: 88px;
    padding-bottom: 88px;
    h1 {
      font-size: 2rem;
      line-height: 2rem;
      margin: 1.5rem 0;
      max-width: 650px;
      @include media-breakpoint-up(md) {
        font-size: 3rem;
        line-height: 3rem;
      }
    }

    &-subtitle {
      font-size: 1.25rem;
      line-height: 2rem;
      color: $tuna-gray-80;
    }

    &-description {
      color: $tuna-gray-80;
      font-weight: 400;
      font-size: 1;
      line-height: 1.5rem;
      max-width: 650px;
      @include media-breakpoint-up(md) {
        font-size: 1.125rem;
        line-height: 2rem;
        color: $tuna-gray-80;
      }
      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &-image {
      margin-bottom: 2rem;
    }
  }

  &__image {
    display: none;
    &--mobile {
      margin-left: $grid-gutter-width / 2;
      margin-right: $grid-gutter-width / 2;
      margin: auto;
    }
  }
}

@include media-breakpoint-up(lg) {
  .c-hero {
    &__intro {
      h1 {
        font-size: 4rem;
        line-height: 3.5rem;
      }

      &-subtitle {
        font-size: 1.5rem;
      }
    }
  }
}
