@import url('https://use.typekit.net/jyh7sik.css');

@import './theme/sdworx-bootstrap.scss';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
// @import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
// @import 'bootstrap/scss/images';
/* @import "code"; */
@import 'bootstrap/scss/grid';
/* @import 'bootstrap/scss/tables'; */
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
// @import "button-group";
@import 'bootstrap/scss/input-group';
/* @import 'bootstrap/scss/custom-forms'; */
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';

/*
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
*/
@import 'bootstrap/scss/media';
/*
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "popover";*/
/* @import 'bootstrap/scss/carousel'; */

@import 'bootstrap/scss/spinners';

@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/print';

@import './theme/section.scss';

@import './components/buttons.scss';
@import './components/navbar.scss';
@import './components/footer.scss';
@import './components/hero.scss';
@import './components/content-block.scss';
@import './components/in-the-picture.scss';
@import './components/doormat.scss';
@import './components/breadcrumb.scss';
@import './components/functional-nav.scss';
@import './components/navigation-highlight.scss';
@import './components/blog-article.scss';
@import './components/article-content-block.scss';
@import './components/person-list.scss';
@import './components/link-block.scss';
@import './components/case.scss';
@import './components/quote.scss';
@import './components/double-quote.scss';
@import './components/event-overview.scss';
@import './components/500.scss';
@import './components/teaser-list.scss';
@import './components/text-and-contact.scss';
@import './components/resource-overview.scss';
@import './components/spotlight.scss';
@import './components/whitepaper.scss';
@import './components/top-navigation.scss';
@import './components/domain-slider.scss';
@import './components/expert.scss';
@import './components/news-block.scss';
@import './components/vision-block.scss';
@import './components/job-details.scss';
@import './components/forms.scss';
@import './components/job-overview.scss';
@import './components/office-page.scss';
@import './components/office-overview.scss';
@import './components/pagination.scss';
@import './components/map.scss';
@import './components/consultants.scss';
@import './components/expert-overview.scss';
@import './components/candidate-detail.scss';
@import './components/skills.scss';
@import './components/summary.scss';
@import './components/language-skills-wrapper.scss';
@import './components/candidate-overview.scss';

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-v27-latin-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-v27-latin-500.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../public/fonts/roboto-v27-latin-700.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: 'nimbus-sans', sans-serif;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

h3,
.h3 {
  color: $orange;
  font-weight: 300;
}

main > section > h2 {
  @include media-breakpoint-up(lg) {
    margin-left: 8.3333333333%;
  }
}

a {
  color: $orange;
  font-weight: bold;
}

.rich-text {
  a {
    color: $orange;
    text-decoration: underline;
  }

  ul {
    list-style: none;
    li:before {
      margin-left: -27px;
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      vertical-align: middle;
      margin-top: -3px;
      margin-right: 20px;
      background-color: #f8ad07;
    }
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: inherit;
    font-weight: inherit;
  }
}

a:hover,
a:active {
  color: $slate-gray-60;
  text-decoration: none;
}

.btn {
  border-radius: 4px;
}

.video-responsive {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-wrapper {
  width: 100%;
}

.tooltip {
  .tooltip-inner {
    padding: 0.25rem 0.5rem;
    background-color: $slate-gray-10;
    color: $tuna-gray;
    font-size: 0.75rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  .arrow::before {
    border-right-color: $slate-gray-10;
  }
}

@include media-breakpoint-up(md) {
  .video-wrapper {
    width: 50%;
    .col-lg-8 & {
      width: 66.66%;
    }
    .col-lg-4 & {
      width: 100%;
    }
  }
}

.break-word {
  overflow-wrap: break-word;
}

.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  overflow: hidden;
  > * {
    margin: 0;
  }
}

.file-name {
  color: inherit;
}

.text-sm {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.25rem;
}
