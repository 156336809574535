.domain-slider {
  padding: 88px 0;

  &__header h4 {
    font-weight: 400;
    font-size: 2rem;
    @include media-breakpoint-up(lg) {
      font-size: 2.5rem;
    }
  }

  &__carousel {
    overflow-x: auto;
    overflow-y: hidden;
    gap: 20px;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    @include media-breakpoint-up(md) {
      overflow-x: hidden;
    }

    &-wrapper {
      overflow-x: hidden;
      width: calc(100% + 25px);
      @include media-breakpoint-up(lg) {
        width: calc(100% + 95px + 25px);
      }
    }

    &__item {
      user-select: none;
      box-sizing: border-box;
      min-width: 80vw;
      width: 80vw;
      height: 70vw;
      max-height: 392px;
      border-radius: 8px;
      scroll-snap-align: start;
      box-shadow: 0px 16px 40px rgba(48, 54, 66, 0.24);
      @include media-breakpoint-up(md) {
        min-width: 20%;
        width: 20%;
        height: 26vw;
      }

      &:last-child {
        margin-right: 50px;
      }

      h5 {
        position: absolute;
        bottom: 0;
        transition: bottom 0.2s ease-in-out;

        @include media-breakpoint-down(md) {
          position: relative;
        }
      }

      span {
        display: block;
        opacity: 0;
        transition: opacity 0.2s 0.1s ease-in-out;

        @include media-breakpoint-down(md) {
          opacity: 1;
          font-size: 12px;
        }
      }

      &:hover h5 {
        bottom: 100%;
        text-decoration: underline;
        @include media-breakpoint-down(md) {
          bottom: 0;
        }
      }
      &:hover span {
        opacity: 1;
      }

      &:hover &__image img {
        filter: grayscale(40%) brightness(50%);
      }

      &__image {
        z-index: 0;
        img {
          filter: grayscale(100%) brightness(50%);
          transition: filter 0.2s ease-out;
        }

        &::after {
          content: '';
          background: rgb(48, 54, 66);
          background: linear-gradient(
            0deg,
            rgba(48, 54, 66, 0.8) 0%,
            rgba(48, 54, 66, 0) 100%
          );
          height: 100%;
          width: 100%;
          position: absolute;
          opacity: 1;
          top: 0;
          left: 0;
          transition: opacity 0.2s ease-in-out;
        }
      }

      &__content {
        z-index: 1;
      }
    }
  }
}

.domain_slider__carousel__controls {
  button {
    top: 30%;
    z-index: 99;
    width: 56px;
    height: 56px;
    background-color: $white;
    background-image: url('/arrow-right-orange.svg');
    background-repeat: no-repeat;
    background-position: center center;
  }

  &__prev {
    left: 0;
    transform: rotate(180deg) translateX(0);
  }

  &__next {
    right: 0;
    transform: rotate(0) translateX(-100%);
  }
}
