section,
section.row {
  margin-bottom: 25px;
  position: relative;

  @include media-breakpoint-up(lg) {
    padding: 88px 0;
  }
}

@include media-breakpoint-up(lg) {
  .section-box {
    padding-left: 95px;
    padding-right: 95px;
  }
}

.section--sparks {
  &::before {
    content: url('/content-block-spark-mobile.svg');
    height: 211px;
    width: 200px;
    top: -50px;
    left: 0px;
    z-index: -1;
    position: absolute;

    @include media-breakpoint-up(lg) {
      content: url('/content-block-spark.svg');
      height: 232px;
      width: auto;
      bottom: 70px;
      top: auto;
      left: 78%;
    }
  }
}

.section--bg {
  &-grey {
    background-color: $tuna-gray-20;
  }
  &-splitYellowLeft {
    margin-top: 30px;
    .btn-link.bg-white {
      color: $purple;
    }

    .c-content-block {
      background-image: linear-gradient(
        to right,
        $yellow,
        $yellow 100%,
        #fff 100%
      );
    }

    @include media-breakpoint-up(lg) {
      .btn-link.bg-white {
        color: inherit;
      }
      .c-content-block{
        background-image: none;
      }
      background-color: transparent;
      background-image: linear-gradient(
        to right,
        $yellow,
        $yellow 30%,
        #fff 30%
      );
    }
  }
  &-splitYellowRight {
    margin-bottom: 30px;
    margin-top: 30px;
    @include media-breakpoint-up(lg) {
      .btn-link.bg-white {
        color: $purple;
      }
    }
    .c-content-block--media-align-left,
    .c-content-block--media-align-top,
    .c-content-block--media-align-right {
      .c-content-block__image {
        background-color: transparent;
        background-image: linear-gradient(
          to right,
          #fff,
          #fff 60%,
          $yellow 30%
        );
      }
    }

    @include media-breakpoint-up(lg) {
      background-color: transparent;
      background-image: linear-gradient(to right, #fff, #fff 60%, $yellow 60%);
    }
  }

  &-splitYellowRight,
  &-splitYellowLeft {
    @include media-breakpoint-up(lg) {
      .c-content-block--media-align-left,
      .c-content-block--media-align-top,
      .c-content-block--media-align-right {
        .c-content-block__image {
          background-color: transparent;
          background-image: none;
          padding: 0;
        }
      }
    }
  }
}
