.vision-block {
  &__button {
    transition: color 0.5s ease-in-out;
    &::after {
      content: url('data:image/svg+xml,%3Csvg width="20" height="18" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" fill="%23E94E0F" %3E%3Cpath d="M11.5148 0.514648L20.0002 8.99995L11.5149 17.4852L10.1007 16.071L16.1718 9.99994H0V7.99994H16.1717L10.1006 1.92886L11.5148 0.514648Z" /%3E%3C/svg%3E');
      display: inline-block;
      margin-left: 15px;
      margin-top: 3px;
      transition: transform 0.5s ease-in-out;
    }

    @include media-breakpoint-up(md) {
      &:hover::after {
        transform: translateX(10px);
      }
      &:hover {
        color: $tuna-gray-60 !important;
      }
    }

  }

  &__person {
    background: $red;
    background-blend-mode: multiply, normal;

    &__image {
      opacity: 0.1;
      filter: grayscale(1) contrast(300%);
    }
    &__quote {
      font-family: $headings-font-family;
      font-size: 24px;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
      padding-right: 25px;
      width: 80%;
      max-width: 550px;
    }
  }
  &__labels {
    counter-reset: label;

    @include media-breakpoint-up(md) {
      min-height: 600px;
    }

    div {
      transition: transform 0.2s ease-in-out;
      cursor: pointer;
      ::before {
        counter-increment: label;
        content: counter(label) '.';
        display: block;
        font-size: 24px;
        font-weight: bold;
      }
      @include media-breakpoint-up(md) {
        &:hover {
          color: rgb(121, 121, 121);
          transform: translateX(30px);
        }
      }
    }
    div:first-child {
      ::before {
        color: $red;
      }
    }
    div:nth-child(2) {
      ::before {
        color: $yellow;
      }
    }
    div:nth-child(3) {
      ::before {
        color: $purple;
      }
    }
    div:nth-child(4) {
      ::before {
        color: $orange;
      }
    }
  }
}
