// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  // color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  user-select: none;
  // background-color: transparent;
  border: $btn-border-width solid transparent;
  border-radius: 4px;
  @include button-size(
    $btn-padding-y,
    $btn-padding-x,
    $btn-font-size,
    $btn-line-height,
    $btn-border-radius
  );
  @include transition($btn-transition);

  @include hover() {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    cursor: not-allowed;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled) {
    cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

    &:active,
    &.active {
      @include box-shadow($btn-active-box-shadow);

      &:focus {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
      }
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn--#{$color} {
    @include button-variant($value, $value);
  }
}

.btn--white {
  color: $purple;

  @include hover() {
    color: $purple;
  }

  &:focus,
  &.focus {
    color: $purple;
  }
}

.btn--red {
  padding: 12px 48px;
}

@each $color, $value in $theme-colors {
  .btn--outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  color: $tuna-gray-80;
  box-shadow: none;
  text-decoration: $link-decoration;
  position: relative;
  padding-right: 50px;
  border: none;

  @include hover() {
    color: $tuna-gray-60;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    text-decoration: none;
    box-shadow: none;
    color: $tuna-gray-60;
  }

  &:not(:disabled):not(.disabled):active {
    color: $tuna-gray-60;
  }
  &.btn--white:not(:disabled):not(.disabled):active {
    color: #212529;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    width: 21px;
    height: 18px;
    right: 10px;
    top: 12px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.515.515L20 9l-8.485 8.485-1.414-1.414L16.17 10H0V8h16.172L10.1 1.929 11.515.515z' fill='%23e94e0f'/%3E%3C/svg%3E");
  }

  &--grey {
    color: $slate-gray-60;
    text-align: left;
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.8 1L16 8L8.8 15M0 8H16H0Z' stroke='%2399A2B7' stroke-width='1.47306'/%3E%3C/svg%3E%0A");
      position: relative;
      left: 10px;
      display: inline-block;
      top: 3px;
      width: 18px;
      height: 16px;
    }
  }

  // No need for an active state here
}

.btn-link-like {
  border: none;
  background-color: transparent;
  color: $red;
  font-size: 1rem;
  text-decoration: underline;
}

//
// Button Sizes
//

.btn--lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn--sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

.btn-dropdown {
  background-color: $white;
  box-shadow: none;
  font-size: 16px;

  &.dropdown-toggle::after {
    content: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z' fill='%23E94E0F'/%3E%3C/svg%3E");
    border: 0;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem $orange;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $tuna-gray-20;
}

.btn-secondary {
  font-weight: 500;
  background-color: $white;
  border-color: $white;
  color: $purple;
  position: relative;
  padding-right: 50px;
  &:hover,
  &:not(:disabled):not(:disabled):active {
    box-shadow: 0 16px 32px rgb(15 17 21 / 16%);
    background-color: $white;
    border-color: $white;
    color: $purple;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  }
  &:focus {
    background-color: $white;
    border-color: $white;
    color: $purple;
  }

  &::after {
    content: '';
    position: absolute;
    width: 21px;
    height: 18px;
    right: 10px;
    top: 10px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.515.515L20 9l-8.485 8.485-1.414-1.414L16.17 10H0V8h16.172L10.1 1.929 11.515.515z' fill='%23F8AD07'/%3E%3C/svg%3E");
  }
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}
