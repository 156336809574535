.c-footer__doormat {
  padding: 2.25rem 0rem;

  &__logo {
    filter: brightness(0) invert(1);
  }
}

.c-footer__doormat--navWrapper {
  .col + .col {
    margin-top: 1.75rem;
  }
}

.c-footer__doormat__navigation-approval {
  span {
    font-size: 12px;
    color: $purple-40;
  }
  div {
    font-size: 14px;
  }
}

.c-footer__doormat--navItem {
  line-height: 2rem;
  font-size: 1.125rem;
  color: #e1c2d5;
  font-weight: 400;

  &:not(.c-footer__doormat--navHeader) {
    margin-top: 1rem;
  }
}

.c-footer__doormat--navHeader {
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}

.c-footer__doormat--buttonDoormatNavWrapper {
  margin-bottom: 2rem;
}

.c-footer__doormat--socialMediaNavWrapper
  .c-footer__doormat--navItem:not(:last-child) {
  margin-right: 0rem;
}

.c-footer__doormat--socialMediaNav-icon:before {
  content: '';
  width: 32px;
  height: 32px;
  display: block;
  background-repeat: no-repeat;
}

.c-footer__doormat--socialMediaNav-linkedin:before {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2.292C0 1.026 1.052 0 2.35 0h27.3C30.948 0 32 1.026 32 2.292v27.416C32 30.974 30.948 32 29.65 32H2.35C1.052 32 0 30.974 0 29.708V2.292Zm9.886 24.496v-14.45H5.084v14.45h4.802Zm-2.4-16.424c1.674 0 2.716-1.108 2.716-2.496-.03-1.418-1.04-2.496-2.684-2.496-1.644 0-2.718 1.08-2.718 2.496 0 1.388 1.042 2.496 2.654 2.496h.032Zm9.816 16.424v-8.07c0-.432.032-.864.16-1.172.346-.862 1.136-1.756 2.464-1.756 1.738 0 2.432 1.324 2.432 3.268v7.73h4.802V18.5c0-4.44-2.368-6.504-5.528-6.504-2.548 0-3.69 1.4-4.33 2.386v.05h-.032l.032-.05v-2.044h-4.8c.06 1.356 0 14.45 0 14.45h4.8Z' fill='%23C385AB'/%3E%3C/svg%3E");
}
.c-footer__doormat--socialMediaNav-facebook:before {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30.933-.231H1.067C.35-.231-.23.349-.23 1.067v29.866c0 .718.58 1.298 1.298 1.298h29.866c.718 0 1.298-.58 1.298-1.298V1.067c0-.718-.58-1.298-1.298-1.298Zm-3.75 9.475H24.59c-2.033 0-2.426.965-2.426 2.386v3.128h4.853l-.633 4.898h-4.22v12.575h-5.06v-12.57H12.87v-4.903h4.233v-3.611c0-4.192 2.56-6.477 6.301-6.477 1.794 0 3.332.134 3.782.195v4.379h-.004Z' fill='%23C385AB'/%3E%3C/svg%3E");
}

.c-footer__doormat--socialMediaNav-twitter:before {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28.571 0H3.43A3.43 3.43 0 0 0 0 3.429V28.57A3.43 3.43 0 0 0 3.429 32H28.57A3.43 3.43 0 0 0 32 28.571V3.43A3.43 3.43 0 0 0 28.571 0ZM25.08 11.343c.014.2.014.407.014.607 0 6.193-4.714 13.329-13.329 13.329-2.657 0-5.121-.772-7.193-2.1.379.042.743.057 1.129.057a9.392 9.392 0 0 0 5.814-2 4.69 4.69 0 0 1-4.378-3.25c.721.107 1.371.107 2.114-.086a4.685 4.685 0 0 1-3.75-4.6v-.057c.621.35 1.35.564 2.114.593a4.675 4.675 0 0 1-2.085-3.9c0-.872.228-1.672.635-2.365a13.302 13.302 0 0 0 9.657 4.9c-.664-3.178 1.715-5.757 4.572-5.757 1.35 0 2.564.565 3.421 1.479a9.18 9.18 0 0 0 2.972-1.129 4.673 4.673 0 0 1-2.057 2.579 9.324 9.324 0 0 0 2.7-.729 9.86 9.86 0 0 1-2.35 2.429Z' fill='%23C385AB'/%3E%3C/svg%3E");
}

.c-footer__doormat--socialMediaNav-instagram:before {
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.996 10.664A5.347 5.347 0 0 0 10.66 16a5.347 5.347 0 0 0 5.336 5.336A5.347 5.347 0 0 0 21.33 16a5.347 5.347 0 0 0-5.335-5.336ZM31.998 16c0-2.21.02-4.399-.104-6.604-.124-2.562-.708-4.836-2.581-6.709C27.434.81 25.166.229 22.604.105 20.394-.019 18.205.001 16 .001c-2.21 0-4.4-.02-6.605.104C6.834.23 4.56.814 2.687 2.687.81 4.564.229 6.834.105 9.396-.019 11.605.001 13.794.001 16c0 2.206-.02 4.399.104 6.605.124 2.561.709 4.835 2.582 6.708 1.877 1.877 4.147 2.458 6.708 2.582 2.21.124 4.4.104 6.605.104 2.21 0 4.399.02 6.604-.104 2.562-.124 4.835-.709 6.709-2.582 1.877-1.877 2.457-4.147 2.581-6.708.128-2.206.104-4.396.104-6.605Zm-16.002 8.21A8.198 8.198 0 0 1 7.786 16a8.198 8.198 0 0 1 8.21-8.21 8.198 8.198 0 0 1 8.21 8.21 8.198 8.198 0 0 1-8.21 8.21ZM24.54 9.371a1.915 1.915 0 0 1-1.917-1.918c0-1.06.857-1.917 1.917-1.917a1.915 1.915 0 0 1 1.772 2.651 1.916 1.916 0 0 1-1.772 1.184Z' fill='%23C385AB'/%3E%3C/svg%3E");
}

.c-footer__doormat--socialMediaNav-xing:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 54 54'%3E%3Cpath fill='%23C385AB' d='M-.2.1h53.8v53.4H-.2z'/%3E%3Cpath fill='%23C385AB' d='M48.1 12.9v26.8c0 2.2-.8 4.1-2.4 5.7-1.6 1.6-3.5 2.4-5.7 2.4H13.3c-2.2 0-4.1-.8-5.7-2.4-1.6-1.6-2.4-3.5-2.4-5.7V12.9c0-2.2.8-4.1 2.4-5.7 1.6-1.6 3.5-2.4 5.7-2.4H40c2.2 0 4.1.8 5.7 2.4 1.6 1.6 2.4 3.5 2.4 5.7zm-23.8 9.7s-1.2-2.1-3.5-6.2c-.4-.6-.9-.9-1.5-.9h-5.1c-.3 0-.6.1-.7.3-.1.2-.1.5 0 .8l3.5 6-5.5 9.7c-.2.3-.2.5 0 .8.1.2.4.4.7.4h5.2c.6 0 1-.3 1.4-1l5.5-9.9zM41.7 8.7c-.1-.2-.4-.3-.7-.3h-5.2c-.6 0-1 .3-1.4 1L23 29.7s2.5 4.5 7.3 13.4c.4.7.9 1 1.5 1h5.1c.3 0 .6-.1.7-.3.1-.2.1-.5 0-.8l-7.3-13.3L41.7 9.5c.2-.3.2-.5 0-.8z'/%3E%3C/svg%3E");
}

@include media-breakpoint-up(lg) {
  .c-footer__doormat {
    padding: 5rem 6.375rem 3.75rem;
    &:before {
      right: 7.5rem;
      top: -1.5rem;
    }
  }
  .c-footer__doormat--navItem--childrenWrapper {
    max-height: 224px;
  }
  .c-footer__doormat--navWrapper {
    .col + .col {
      margin-top: 0;
    }
  }
}
