$section: '.c-content-block-section';
$block: '.c-content-block';

#{$section} {
  @include media-breakpoint-up(lg) {
    padding-left: 95px;
    padding-right: 95px;
  }
  & .row {
    @include media-breakpoint-up(lg) {
      padding-top: 200px;
      padding-top: 0;
    }
  }
}

#{$block} {
  margin-bottom: 30px;
  h2 {
    font-weight: 700;
    font-size: 3rem;
  }
  h3 {
    font-weight: 700;
    font-size: 2rem;
  }
  h4 {
    font-weight: 700;
    font-size: 1.5rem;
  }
  h5 {
    font-weight: 700;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  &--bg {
    &-none,
    &-grey {
      h3 {
        color: $tuna-gray;
      }
      h4 {
        color: $slate-gray;
      }
    }

    &-none {
      .toptitle {
        color: $purple;
      }
    }

    &-grey,
    &-darkGrey {
      .toptitle {
        color: $red;
      }
    }

    &-orange,
    &-purple,
    &-red {
      .toptitle {
        color: $white;
      }
    }

    &-orange,
    &-purple,
    &-red,
    &-darkGrey {
      h2,
      h3,
      h4,
      h5 {
        color: $white;
      }
      color: $white;
      .rich-text a {
        color: $white;
        &:hover {
          color: $slate-gray-60;
        }
      }
    }

    &-orange,
    &-purple,
    &-red,
    &-grey,
    &-darkGrey {
      padding: 30px;
      height: auto;

      @include media-breakpoint-up(lg) {
        height: 100%;
      }

      &#{$block}--sparks {
        position: relative;
        margin-top: 60px;
        margin-bottom: 60px;
        padding: 60px 30px;

        @include media-breakpoint-up(xl) {
          padding: 60px;
        }
      }

      .btn--white {
        color: $purple;
      }
    }

    &-orange,
    &-purple,
    &-red,
    &-grey,
    &-darkGrey {
      .btn-link.bg-white {
        color: $purple;
      }
    }

    &-orange {
      background-color: $orange;
    }
    &-purple {
      background-color: $purple;
    }
    &-red {
      background-color: $red;
    }
    &-grey {
      background-color: $tuna-gray-20;
    }
    &-darkGrey {
      background-color: $tuna-gray;
    }
  }

  &--align {
    &-left #{$block}__text-wrapper {
      text-align: left;
    }
    &-center #{$block}__text-wrapper {
      text-align: center;
    }
    &-right #{$block}__text-wrapper {
      text-align: right;
    }
  }
  &--media-align {
    &-left,
    &-right {
      #{$block}__image,
      #{$block}__video,
      #{$block}__text-wrapper {
        padding: 30px 25px 30px;
      }

      display: flex;
      flex-direction: column;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        #{$block}__image,
        #{$block}__video,
        #{$block}__text-wrapper {
          width: 50%;
        }
        #{$block}__video {
          padding-bottom: 28.125%;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      &-right {
        #{$block}__image,
        #{$block}__video {
          order: 2;
        }
        #{$block}__text-wrapper {
          padding-right: 25px;
        }
      }
      &-left {
        #{$block}__text-wrapper {
          padding-left: 25px;
        }
      }
    }
  }

  &__iframe {
    iframe {
      width: 99%;
    }
  }

  &__video,
  &__description {
    margin-bottom: 2rem;
  }

  &__description {
    overflow-wrap: break-word;  
  }

  &__documentList {
    list-style-type: none;
    padding-left: 0;
  }

  &__video {
    position: relative;

    @include media-breakpoint-up(md) {
      padding-bottom: 56.25%;
      height: 0;
    }

    .video-wrapper {
      width: 100%;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
