.c-breadcrumb {
  ol {
    list-style: none;

    .nav-link {
      color: $slate-gray;
    }

    a,
    a:hover {
      color: $slate-gray;
    }

    li .nav-link {
      span {
        font-size: 0.875rem;
        margin-top: 3px;
        color: $slate-gray;
      }
    }

    li:first-of-type a {
      padding-left: 0;
      position: relative;
      padding-left: 35px;

      &::before {
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIj48L3BhdGg+PGcgZmlsbD0iIzg5OGU5YSI+PHBhdGggZD0iTTg2LDE1LjA0NzJsLTc4LjgzMzMzLDcwLjk1MjhoMjEuNXY2NC41aDUwLjE2NjY3di00M2gxNC4zMzMzM3Y0M2g1MC4xNjY2N3YtNjQuNWgyMS41ek04NiwzNC4zMzU2MWw0MywzOC43MDI4djUuNzk0OTJ2NTcuMzMzMzNoLTIxLjV2LTQzaC00M3Y0M2gtMjEuNXYtNjMuMTI4MjZ6Ij48L3BhdGg+PC9nPjwvZz48L3N2Zz4=');
        display: inline-block;
        position: absolute;
        left: 0;
        margin-right: 15px;
        text-decoration: none;
      }
    }

    li:not(:last-of-type):hover span[itemprop='name'] {
      text-decoration: underline;
    }

    li .nav-link__last span {
      font-weight: bold;
      color: $tuna-gray-80;
    }
  }
}

span.c-breadcrumb__delimiter {
  background-color: $tuna-gray-80;
  width: 1px;
  height: 25px;
}
