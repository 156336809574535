.c-case {
  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 30px;
  }
  h5 {
    font-size: 1.5rem;
    font-weight: 500;
    color: $tuna-gray-60;
    line-height: 2rem;
  }

  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 3rem;
      line-height: 3.5rem;
    }
    h5 {
      font-size: 1.5rem;
    }
  }

  &__spacing {
    margin-bottom: 64px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
    }
  }
}
