.expert-block {
  &__image::after {
    content: '';

    @include media-breakpoint-up(lg) {
      content: url('/expert-spark.svg');
      position: absolute;
      right: -14%;
      top: -40px;
    }
  }
  &__text {
    line-height: 2rem;
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  &__info {
    @include media-breakpoint-up(lg) {
      padding: 15% 0;
    }
  }

  &__person-info {
    bottom: 0;
    left: 0;
    width: 75%;
    padding: 8px 14px;
    font-size: 14px;

    &::before {
      content: '';
      position: absolute;
      right: -20px;
      bottom: 0;
      width: 30px;
      height: 100%;
      transform: skew(20deg);
      background: $purple;
    }
  }
}
