.job-details {
  display: grid;
  @include media-breakpoint-up(xl) {
    grid-template-columns: 60% 30%;
    grid-column-gap: 10%;
    // grid-template-rows: auto;
    grid-template-areas:
      'leftcolumn  rightcolumn'
      'leftcolumn  rightcolumn'
      'leftcolumn  rightcolumn';
  }

  &__company-logo {
    max-width: 250px;
    @include media-breakpoint-up(xl) {
      width: 100%;
      max-width: 100%;
      grid-area: 1 / rightcolumn;
    }
  }

  &__extra-info {
    @include media-breakpoint-up(xl) {
      position: sticky;
      top: 70px;
      grid-area: 2 / rightcolumn;
      grid-row: span(2);
    }
    &__block {
      background-color: $tuna-gray-20;
      border-radius: 8px;
    }
  }

  &__app-button {
    z-index: 2;
    @include media-breakpoint-up(xl) {
      position: fixed;
      bottom: 1.5rem;
      left: 1.5rem;
      right: 1.5rem;
      position: static;
      grid-area: 2 / rightcolumn;
      align-self: baseline;
    }
  }

  &__attributesDescriptionsWrapper {
    display: contents;
    @include media-breakpoint-up(xl) {
      display: block;
      grid-area: 2 / leftcolumn;
      grid-row-end: 4;
      width: 100%;
    }
    & .col {
      border-bottom: 1px solid $slate-gray-20;

      @include media-breakpoint-up(xl) {
        border-bottom: 0;
        border-right: 1px solid $slate-gray-20;
      }
    }

    & .col:last-child {
      border: 0;
    }
  }
  &__attributes {
    @include media-breakpoint-up(xl) {
      grid-row-start: initial;
    }
  }

  &__title {
    @include media-breakpoint-up(xl) {
      grid-area: 1 / leftcolumn;
      align-self: center;
      font-size: 64px;
    }
  }

  &__descriptions {
    &-title {
      font-size: 1.75rem;
      line-height: 1;
      @include media-breakpoint-up(xl) {
        font-size: 2rem;
      }
    }
    &-text {
      line-height: 2rem;
    }
  }

  &__application-process {
    overflow-x: hidden;
    width: calc(100% + 25px);
    h2 {
      font-size: 2rem;
      font-weight: 400;
      line-height: 1;
      color: $tuna-gray-80;
      @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
      }
    }
    @include media-breakpoint-up(lg) {
      width: calc(100% + 95px + 25px);
    }
    &__carousel {
      overflow-x: auto;
      overflow-y: hidden;
      gap: 20px;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      @include media-breakpoint-up(lg) {
        overflow-x: hidden;
      }

      &__controls {
        & button {
          top: 30%;
          z-index: 99;
          width: 56px;
          height: 56px;
          background-color: #ffffff;
          background-image: url(/arrow-right-orange.svg);
          background-repeat: no-repeat;
          background-position: center center;
        }

        &__next {
          right: 0;
          transform: rotate(0);
        }
        &__prev {
          left: 0;
          transform: rotate(180deg);
        }
      }
      &__item {
        min-width: 80%;
        width: 80%;
        overflow: hidden;
        scroll-snap-align: start;
        box-shadow: 0px 8px 24px rgba(48, 54, 66, 0.16);
        @include media-breakpoint-up(lg) {
          min-width: 25vw;
          width: 25vw;
        }

        &:last-child {
          margin-right: 25px;
        }
        &__image {
          min-height: 200px;
          max-height: 200px;
        }

        &__title {
          color: $tuna-gray-80;
        }
        &__text {
          line-height: 2rem;
        }
      }

      &__item:nth-child(1n)
        .job-details__application-process__carousel__item__badge {
        background: $yellow;
        color: black;
      }
      &__item:nth-child(2n)
        .job-details__application-process__carousel__item__badge {
        background: $purple;
        color: white;
      }
      &__item:nth-child(3n)
        .job-details__application-process__carousel__item__badge {
        background: $red;
        color: white;
      }
      &__item:nth-child(4n)
        .job-details__application-process__carousel__item__badge {
        background: $orange;
        color: white;
      }
    }
  }

  &__benefits {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(86px, 1fr));
    gap: 12px 16px;

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr;
      gap: 8px;
    }
  }

  &__benefit {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'icon'
      'text';
    gap: 8px;

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr auto;
      grid-template-rows: auto;
      grid-template-areas: 'text icon';
    }

    &--text {
      grid-area: text;
      font-size: 0.75rem;

      @include media-breakpoint-up(xl) {
        font-size: 1.125rem;
      }
    }

    &--icon-container {
      grid-area: icon;
    }

    &--icon {
      padding: 8px;
      background-color: $white;
      border: 1px solid $slate-gray-20;

      @include media-breakpoint-up(xl) {
        padding: 0;
        background-color: transparent;
        border: none;
      }

      & > span {
        display: block !important;
      }
    }
  }
  &__person {
    width: 250px;
    height: 250px;
    @include media-breakpoint-up(xl) {
      width: 500px;
      height: 500px;
    }
  }
}
