// New breakpoints
//
//
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1920px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1680px,
);

$grid-gutter-width: 50px !default;

// COLORS
//
//
$purple: #870b58;
$purple-60: #a54881;
$purple-40: #c385ab;
$purple-20: #e1c2d5;

$orange: #e94e0f;
$orange-60: #ee7a4b;
$orange-40: #f3a687;
$orange-20: #f9d2c3;

$red: #e4003a;
$red-60: #ea3f6b;
$red-40: #f17f9c;
$red-20: #f8bfcd;
$red-error: #ff005c;

$yellow: #f8ad07;
$yellow-60: #f9c145;
$yellow-40: #f9c145;
$yellow-20: #fdeac1;

$tuna-gray: #303642;
$tuna-gray-80: #555d71;
$tuna-gray-60: #898e9a;
$tuna-gray-40: #dadada;
$tuna-gray-20: #f6f6f6;
$tuna-gray-10: #e9eaed;

$slate-gray: #7783a0;
$slate-gray-60: #99a2b7;
$slate-gray-40: #bbc1cf;
$slate-gray-20: #dde0e7;
$slate-gray-10: #f1f4f8;
$slate-gray-5: #f8f9fb;

$white: #ffffff;
$black: #000;
$light-transparent: rgba(0, 0, 0, 0.3);

$primary: $red;
$secondary: $tuna-gray;
$light: $slate-gray-10;
$dark: $tuna-gray;
$gray: $slate-gray;
$gray-dark: $tuna-gray;
$transparent: transparent;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'purple': $purple,
    'orange': $orange,
    'red': $red,
    'yellow': $yellow,
    'tuna-grey': $tuna-gray,
    'slate-grey': $slate-gray-20,
    'dark-grey': $tuna-gray-80,
    'white': $white,
    'black': $black,
    'transparent': $transparent,
  ),
  $theme-colors
);

// TYPOGRAPHY
//
//
$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

$headings-font-family: Nimbus-sans, 'Nimbus Sans', Roboto, -apple-system,
  BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans',
  sans-serif;
$headings-font-weight: 700;

$font-size-base: 1.125rem;
$h1-font-size: 5rem;
$h2-font-size: 3rem;
$h3-font-size: 2rem;
$h4-font-size: 1.5rem;

$display1-weight: 700;
$display2-weight: 700;
$display3-weight: 700;
$display4-weight: 400;
$display5-weight: 300;
$display6-weight: 700;

$h4-lineheight: 1;

h4,
h4.h4 {
  line-height: $h4-lineheight;
}

.toptitle {
  font-size: 0.75rem;
}

$lead-font-weight: 500;

// Main Navigation
$transition-speed: 250ms;

// Options
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: false;
$enable-responsive-font-sizes: true;

// Body
$body-color: $tuna-gray;

// Links
$link-color: $orange;

// BUTTONS
$btn-border-radius: 4px;
$btn-border-radius-lg: 4px;
$btn-border-radius-sm: 4px;
$btn-font-weight: 500;

.btn {
  box-shadow: 0px 8px 24px rgba(15, 17, 21, 0.1);
  border-radius: $btn-border-radius;
}

.btn:active {
  box-shadow: none;
}

.btn:focus {
  box-shadow: 0px 16px 32px rgba(15, 17, 21, 0.16);
}

.btn--tuna-grey {
  color: $yellow;
}

.text-underline {
  text-decoration: underline;
}

// Navbar

$navbar-nav-link-padding-x: 1.125rem;

.row {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
