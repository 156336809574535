.c-navbar {
  z-index: 1001;
  @include media-breakpoint-up(lg) {
    padding: 0 95px;
  }
  .navbar-expand-lg {
    align-items: flex-start;
  }
  .c-navbar__subnav,
  .c-navbar__subnav-mobile {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    background-color: $white;
    padding-left: 132px;
    position: absolute;
    display: none;
    border-top: 1px solid $slate-gray-20;
    transition: height $transition-speed ease;
    overflow: hidden;
  }

  .c-navbar__subnav-mobile__top-nav-mobile {
    background-color: #f1f4f8;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px 40px;
    padding: 0 14px;
    a {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .c-navbar__subnav-group .nav-item {
    padding: 0 !important;
  }
  .c-navbar__subnav-header {
    border-bottom: 1px solid $slate-gray-20;
    font-weight: 500;
    padding-top: 40px;
    margin-bottom: 40px;
  }

  .c-navbar__subnav-group,
  .c-navbar__subnav-mobile-group {
    @include media-breakpoint-up(lg) {
      margin-right: 40px;
    }

    .nav-item {
      padding: 5px 0;
      &.hasChildren,
      &.hasNoChildren {
        a {
          margin-bottom: 0;
          font-weight: 500;
          color: #870b58;
        }

        a.grandchild {
          color: $black;
          &:hover {
            color: $slate-gray-60;
          }
        }
      }

      a {
        margin-bottom: 0;
        display: inline-block !important;
        height: auto;
        color: $tuna-gray-80;
        border-bottom: none;
        &:active,
        &:focus,
        &:hover {
          border: unset;
          color: $purple-40;
          border-bottom: none;
        }
      }
    }
  }

  .navbar-nav > .nav-item {
    padding: 0 20px;
    &:last-child {
      padding: 0;
    }
    a {
      cursor: default;
      font-weight: 500;
      position: relative;
      display: flex;
      padding: 6px 0;
      font-size: 18px;
      align-items: center;
      border-bottom: 3px solid transparent;
      color: $tuna-gray;
      white-space: nowrap;
    }
    & > a {
      height: 70px;
    }

    a:active,
    a:focus,
    a:hover {
      background: 0 0;
      border-bottom: 3px solid $purple;
      color: $purple-40;
      cursor: pointer;
    }
    .c-navbar__subnav-banner-textWrapper {
      a:focus,
      a:hover {
        border-color: transparent;
      }
    }
  }

  .nav-item .c-navbar__subnav.active {
    display: flex;
  }

  .navbar-toggler {
    z-index: 110;
    margin-right: 1rem;
  }

  .navbar-toggler:focus {
    outline: none;
  }

  .navbar-brand {
    width: 236px;
    margin-right: 0;
    @include media-breakpoint-up(lg) {
      padding-right: 64px;
      width: auto;
    }
  }

  .c-navbar__nav-desktop {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .c-navbar__nav-mobile {
    display: none;
    width: 100%;
    padding: 100px 0px;

    &__innerWrapper {
      padding: 12px 0;
    }
    &--subNavBtn {
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.1);
      color: red;
      border: 0;
      font-size: 2rem;
    }
  }
  .c-navbar__subnav-mobile__innerWrapper {
    padding: 12px 32px;
    .nav-item {
      border-top: 0 !important;
      width: 100%;
      a {
        margin-bottom: 0;
      }
    }
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    height: 100%;
    position: fixed;
    background-color: white;
    width: 100%;
    left: 0%;
    top: 0;
    overflow-x: hidden;
    transition: height 0.1s ease-in-out;

    .c-navbar__subnav-mobile-group {
      padding: 0;
    }

    .navbar-nav,
    .nav-item {
      color: $tuna-gray !important;
      margin-bottom: 0px;
      font-size: 1rem;
      font-weight: 500;
      position: relative;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .navbar-nav {
      padding: 0;
      align-items: normal !important;
    }

    .nav-item {
      padding: 0;
      border-top: 1px solid $slate-gray-10;

      a {
        height: auto !important;
        display: flex;
        font-size: 16px;
      }
    }

    a {
      color: $tuna-gray;
      background-color: transparent;
      border: none;
    }

    .c-navbar__subnav,
    .c-navbar__subnav-mobile {
      background-color: $white;
      border: none;

      .menu {
        width: 100%;
        max-width: unset;
        min-width: unset;
      }
      a {
        width: 80%;
        height: 4.5rem;
      }
    }

    .c-navbar__subnav-mobile-title {
      border-top: none;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      position: relative;
      padding-left: 16px;
      padding-top: 10px;
      button {
        position: absolute;
        left: 16px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.96.086.587 6.46.07 7l.516.54 6.375 6.374 1.078-1.078L2.953 7.75H18v-1.5H2.953L8.04 1.164 6.961.086Z' fill='%23E4003A'/%3E%3C/svg%3E");
        width: 18px;
        height: 14px;
        border: none;
        box-shadow: none;
        background-color: transparent;
        top: 60%;
        transform: translateY(-50%);
      }
    }

    @include media-breakpoint-up(md) {
      .c-navbar__nav-desktop,
      .c-navbar__subnav {
        display: flex;
      }
      .c-navbar__nav-mobile,
      .c-navbar__subnav-mobile {
        display: none;
      }
    }
    @include media-breakpoint-down(md) {
      .c-navbar__nav-desktop,
      .c-navbar__subnav {
        display: none;
      }
      .c-navbar__nav-mobile,
      .c-navbar__subnav-mobile {
        display: block;
      }
    }

    /* Mobile Slide Animation Classes  */
    .c-navbar__menu-slide-enter {
      position: absolute;
      transform: translateX(100vw);
    }
    .c-navbar__menu-slide-enter-active {
      transform: translateX(0vw);
      transition: all $transition-speed linear;
    }
    .c-navbar__menu-slide-exit {
      position: absolute;
    }
    .c-navbar__menu-slide-exit-active {
      transform: translateX(100vw);
      transition: all $transition-speed linear;
    }
  }

  /* Define the shape and color of the hamburger lines */
  .navbar-toggler span {
    display: block;
    background-color: $tuna-gray;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;
  }

  .close-btn {
    padding: 0;
    border: none;
    &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 25px;
      background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 0.25C4.62402 0.25 0.25 4.62402 0.25 10C0.25 15.376 4.62402 19.75 10 19.75C15.376 19.75 19.75 15.376 19.75 10C19.75 4.62402 15.376 0.25 10 0.25ZM10 1.75C14.5645 1.75 18.25 5.43555 18.25 10C18.25 14.5645 14.5645 18.25 10 18.25C5.43555 18.25 1.75 14.5645 1.75 10C1.75 5.43555 5.43555 1.75 10 1.75ZM7.16406 6.08594L6.08594 7.16406L8.92188 10L6.08594 12.8359L7.16406 13.9141L10 11.0781L12.8359 13.9141L13.9141 12.8359L11.0781 10L13.9141 7.16406L12.8359 6.08594L10 8.92188L7.16406 6.08594Z' fill='%23303642'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      width: 30px;
      height: 30px;
      display: block;
    }
    &.collapsed:after {
      top: 50%;
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(0, 0, 0, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      color: $black;
      content: '';
    }
  }

  &__overlay.active:before {
    @include media-breakpoint-up(lg) {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }
  }
}
