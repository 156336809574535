.c-teaser-list {
  &.section--bg-white {
    margin-top: 50px;
    padding-top: 74px;
  }

  &__heading {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: $tuna-gray;
    max-width: 845px;
    margin-bottom: 46px;
  }

  &__cards {
    gap: 22px;

    > a {
      transition: transform 0.2s;

      &.c-teaser-list__card:hover {
        transform: scale(1.05);
      }
    }
  }

  &__card {
    padding: 35px 33px;
    background: #fffdfd;
    box-shadow: 0px 4px 4px rgba(190, 185, 185, 0.25);
    border-radius: 2.8px;

    @include media-breakpoint-up(lg) {
      width: 20%;
    }

    &-label {
      margin-bottom: 25px;

      .c-teaser-list-usp &,
      .c-teaser-list-data & {
        flex-direction: column;
        margin-bottom: 20px;
      }
    }

    &-title {
      font-size: 18px;
      line-height: 18px;
      color: $tuna-gray;
      margin-left: 7px;

      .c-teaser-list-usp &,
      .c-teaser-list-data & {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: $orange;
        text-align: center;
      }
    }

    &-text {
      font-size: 16px;
      line-height: 24px;
      color: $tuna-gray-80;
      margin-bottom: 20px;

      .c-teaser-list-usp &,
      .c-teaser-list-data & {
        font-size: 18px;
        line-height: 32px;
        text-align: center;
      }
    }

    &-button {
      margin-top: 20px;
      font-size: 14.7306px;
      line-height: 24px;
      color: $tuna-gray;
      white-space: nowrap;

      &::after {
        content: '';
        width: 14px;
        height: 12px;
        display: inline-block;
        margin-left: 8px;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.3055 0.607666L12.94 6.13164L7.3055 11.6556M0.418945 6.13164H12.94H0.418945Z' stroke='%23303642' stroke-width='1.47306'/%3E%3C/svg%3E");
      }
    }

    &-data {
      font-size: 36px;
      line-height: 81px;
      color: $tuna-gray-60;
    }
  }

  &-usp {
    .image-wrapper {
      box-shadow: 0px 7.24013px 21.7204px rgba(15, 17, 21, 0.1);
      border-radius: 3.62006px;
      width: 158px;
      height: 144px;
      padding: 24px;
      margin-bottom: 64px;
    }
  }

  &-data {
    .image-wrapper {
      padding: 24px;
      border-radius: 50%;
      width: 93px;
      height: 93px;
      background: #f0f0f0;
      padding: 20px;
    }
  }
}
