.c-blog-article {
  h1 {
    font-size: 2rem;
  }
  @include media-breakpoint-up(md) {
    h1 {
      font-size: 3.5rem;
    }
  }
  &__dateDuration {
    font-size: 0.8125rem;
  }

  &__person {
    &-outerWrapper {
      background: linear-gradient(90deg, #fcfcfc 0, #f6f6f6 100%);
    }
    &-wrapper {
      padding: 2rem;
    }
    &-text-wrapper {
      div:first-of-type {
        margin-bottom: 0.5rem;
      }
    }
    &-img-wrapper {
      max-width: 18rem;
      margin-bottom: 2.5rem;
      img {
        border-radius: 50%;
      }
    }
    &-text-name {
      margin-left: 0.25rem;
      font-weight: 700;
    }

    @include media-breakpoint-up(lg) {
      &-wrapper {
        padding: 8rem;
        margin: 1.5rem 8rem;
      }
      &-text-wrapper {
        padding-left: 8rem;
        padding-right: 8rem;
      }
      &-img-wrapper {
        max-width: 8rem;
      }
    }
  }

  .c-heading__wrapper {
    padding: 0;
  }
}
