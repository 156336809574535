.language-skills-wrapper {
  gap: 20px;

  &__item {
    display: grid;
    grid-template-areas:
      'language progress'
      'language level';
    grid-template-columns: 100px 1fr;
    grid-gap: 0 16px;

    &--language {
      grid-area: language;
      color: $tuna-gray;
      font-size: 18px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &--progress-wrapper {
      grid-area: progress;
      background-color: $slate-gray-10;
      border-radius: 100vmax;
    }

    &--progress {
      border-radius: 100vmax;
      height: 8px;

      &-1 {
        background-color: $purple;
        width: 25%;
      }

      &-2 {
        background-color: $red;
        width: 50%;
      }

      &-3 {
        background-color: $orange;
        width: 75%;
      }

      &-4 {
        background-color: $yellow;
        width: 100%;
      }
    }

    &--level {
      grid-area: level;
      color: $slate-gray-60;
      font-size: 12px;
    }
  }
}
