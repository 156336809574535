.c-resource-overview {
  &__hero {
    display: grid;
    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: 2;
    }
    &-overlay {
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.35);
    }
    &-item {
      &__title {
        bottom: 54px;
        left: 36px;
        padding-right: 54px;
        z-index: 2;
      }
      &__tag {
        bottom: 0;
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 1px;
        padding: 2px 25px;
        z-index: 2;
        left: 0;
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-color: $yellow;
          z-index: -1;
        }
        &:after {
          content: ' ';
          display: block;
          position: absolute;
          right: -10px;
          bottom: 0;
          width: 0;
          height: 0;
          border-color: transparent transparent transparent $yellow;
          border-style: solid;
          border-width: 22px 0 0 10px;
        }
      }
      &-0 {
        grid-column: 1/-1;
        grid-row: 1/-1;
        display: flex;
      }
      &-1,
      &-2,
      &-3,
      &-4 {
        display: none;
      }
      @include media-breakpoint-up(lg) {
        &-0 {
          grid-column: 1/3;
          grid-row: 1/3;
          max-height: 480px;
          & > span:first-of-type {
            width: 100% !important;
          }
        }
        &-1,
        &-2,
        &-3,
        &-4 {
          display: flex;
          max-height: 240px;
        }
        &-1 {
          grid-column: 3/4;
          grid-row: 1/2;
        }
        &-2 {
          grid-column: 4/5;
          grid-row: 1/2;
        }
        &-3 {
          grid-column: 3/4;
          grid-row: 2/3;
        }
        &-4 {
          flex-direction: column;
          align-items: flex-start;
          background-color: $purple;
          grid-column: 4/5;
          grid-row: 2/3;
          padding: 16px 32px;
          span {
            font-size: 16px;
          }
          .c-resource-overview__hero-item__title {
            bottom: initial;
            left: initial;
            padding-right: 0;
            position: relative;
            margin-bottom: 1rem;
          }
          .btn {
            margin-top: auto;
          }
        }
      }

      img {
        transition: transform 0.3s ease-in-out;
        transform: scale(1);
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  &__topics {
    &--mobile {
      margin-top: 32px;
      margin-bottom: 32px;
      .dropdown {
        width: 100%;
        & > button {
          font-size: 0.875rem;
          color: $tuna-gray-80;
          width: 100%;
          border: 1px solid $tuna-gray-40;
          box-shadow: none;
          text-align: left;
          display: flex;
          padding: 1em 1.5em;
          text-transform: uppercase;
          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled):focus,
          &:hover {
            background-color: inherit;
            border-color: $tuna-gray-40;
            color: inherit;
            box-shadow: none;
          }
          &:after {
            content: '+';
            right: 0;
            border: none;
            margin-left: auto;
            font-size: 20px;
            line-height: 1;
          }
          &[aria-expanded='true'] {
            &:after {
              content: '-';
            }
          }
        }
        &-menu {
          width: 100%;
        }
      }
    }
    &--desktop {
      display: none;
      padding-left: 54px;
      background-color: $tuna-gray-20;
      width: 100%;
      span {
        // white-space: nowrap;
        padding-top: 32px;
        padding-bottom: 32px;
      }
      & &-title {
        font-size: 1.5rem;
        font-weight: bold;
        padding-top: 28px;
        padding-bottom: 28px;
      }
      &__list {
        overflow-x: hidden;
        margin-left: 100px;
        flex-grow: 1;
        transition: transform 0.5s;
        &-inner {
          transition: transform 0.5s;
          transform: translateX(0);
        }
        &-item {
          min-width: 25%;
          color: $tuna-gray-80;
          font-weight: 500;
          padding-top: 32px;
          padding-bottom: 32px;
          @include media-breakpoint-up(xl) {
            min-width: 20%;
          }
          &.active {
            color: $purple;
            background-color: $tuna-gray-40;
          }
        }
      }
      &__btn {
        &.prev {
          margin-left: 100px;
          & + .c-resource-overview__topics--desktop__list {
            margin-left: 0;
          }
        }
        &.next {
          display: none;
          @media (max-width: 1200px) {
            &.moreThan4Topics:not(.moreItemsRemaining) {
              display: block;
            }
          }
          @include media-breakpoint-up(xl) {
            &.moreThan5Topics:not(.moreItemsRemaining) {
              display: block;
            }
          }
        }
        padding: 32px;
        background-color: $tuna-gray-40;
        font-weight: 500;
        color: $tuna-gray-80;
        &.prev {
          display: block;
        }
        &:after {
          content: '';
          display: inline-block;
          height: 13px;
          margin-left: 12px;
          width: 8px;
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.327 0c.171 0 .34.03.494.09.154.06.29.146.398.254l5.521 5.57a.83.83 0 01.26.59.83.83 0 01-.26.59l-5.715 5.57c-.194.19-.473.31-.775.332a1.33 1.33 0 01-.837-.21.892.892 0 01-.408-.63.818.818 0 01.26-.68L5.373 6.5.436 1.523a.853.853 0 01-.256-.478A.776.776 0 01.282.53C.376.37.524.234.709.14c.185-.093.4-.142.618-.14z' fill='%237C7C7C'/%3E%3C/svg%3E");
        }
        &.prev:after {
          margin-left: 0;
          transform: rotate(180deg);
        }
      }
    }
    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__resources {
    .col-sm-6 {
      padding-bottom: 30px;
    }
    @include media-breakpoint-up(lg) {
      .col-sm-6 {
        padding-bottom: 60px;
      }
    }
  }
}

.c-resource-preview {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__animate {
    transition: transform 0.2s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
  &__image {
    min-height: 350px;
    max-height: 350px;
    overflow: hidden;
  }
  &__tag {
    color: $white;
    font-size: 0.75rem;
    line-height: 1rem;
    padding: 4px 10px;
    position: absolute;
    left: 0px;
    top: 0px;
    background: $red;
  }
  &__title {
    color: $tuna-gray-80;
    font-size: 18px;
    margin-top: 0;
    font-family: $headings-font-family;
  }
  &__text-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
  }

  &__date {
    font-size: 75%;
    color: $tuna-gray-80;
    margin-left: auto;
  }
  &__description {
    font-size: 14px;
    margin-bottom: 24px;
  }
}

.resource-row {
  display: none;
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;
  }
}
