.c-consultant {
  background-color: white;
  box-shadow: 0px 4px 8px 0px #3036421a;
  padding: 20px 24px;

  &-section {
    background-color: $purple;
    padding-block: 2rem;
    padding-inline: 2rem;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 76px 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      'avatar tags'
      'avatar fullname'
      'avatar info'
      'avatar button';
    column-gap: 16px;

    @include media-breakpoint-up(lg) {
      grid-template-areas:
        'avatar tags'
        'fullname fullname'
        'info info'
        'button button';
    }
  }

  &__expertise-wrapper {
    grid-area: tags;
    gap: 4px;

    @include media-breakpoint-up(lg) {
      gap: 8px;
    }
  }

  &-expertise {
    background-color: $slate-gray;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 20px;
    padding-block: 2px;
    padding-inline: 6px;
    min-width: 64px;
    width: fit-content;
  }

  &__image {
    grid-area: avatar;

    img {
      border-radius: 100vmax;
    }
  }

  &__name {
    grid-area: fullname;
    margin: 8px 0 0 0;
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    color: $black;
    word-break: break-word;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
      line-height: 22px;
      font-weight: 400;
      color: $tuna-gray;
    }
  }

  &__info {
    grid-area: info;
    gap: 4px;

    &--job {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 24px;
      color: $tuna-gray-80;

      @include media-breakpoint-up(lg) {
        font-weight: 500;
        font-size: 1rem;
        line-height: 22px;
      }
    }

    @include media-breakpoint-up(lg) {
      gap: 4px 24px;
      margin-top: 8px;
    }
  }

  &-field-wrapper {
    gap: 8px;
  }

  &-icon-wrapper {
    min-width: 22px;

    @include media-breakpoint-up(lg) {
      min-width: unset;
    }
  }

  &-info-text {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 16px;
    color: $tuna-gray-80;
    word-break: break-word;
  }

  &__button {
    grid-area: button;
    width: fit-content;
  }

  @include media-breakpoint-up(lg) {
    padding: 28px 32px;
  }
}
