.job-overview {
  &__top-search-panel {
    &__filter-number {
      background-color: $slate-gray-10;
      font-size: 16px;
      &__number {
        height: 30px;
        width: 30px;
      }
      &__text {
        font-size: 18px;
      }
    }

    &__distance {
      background-color: $slate-gray-10;
      font-size: 16px;
      input[type='range'] {
        height: 29px;
        -webkit-appearance: none;
        margin: 0;
        width: 100%;
        background-color: transparent;
      }

      input[type='range']::-webkit-slider-runnable-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: #e94e0f;
        color: #3f3f3f;
        border-radius: 5px;
      }
      input[type='range']::-webkit-slider-thumb {
        border: 3px solid $slate-gray-10;
        height: 22px;
        width: 22px;
        box-sizing: border-box;
        border-radius: 8px;
        background: #e94e0f;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -8.5px;
      }

      input[type='range']::-moz-range-track {
        width: 100%;
        height: 4px;
        cursor: pointer;
        background: #e94e0f;
        color: #3f3f3f;
        border-radius: 5px;
      }

      input[type='range']::-moz-range-thumb {
        border: 3px solid $slate-gray-10;
        height: 22px;
        width: 22px;
        box-sizing: border-box;
        border-radius: 8px;
        background: #e94e0f;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -8.5px;
      }
    }

    &__search-box {
      input {
        padding: 22px 15px 18px 60px;
        font-size: 18px;
        background-color: transparent;
        border: 1px solid $slate-gray-20;

        &:hover {
          outline-offset: 0;
          border: 1px solid $black;
        }
        &:focus-visible {
          outline-offset: 0px;
          outline: solid black 2px;
          border: none;
        }
      }
    }

    & .location-input {
      border: 0;

      &__control {
        border: 1px solid $slate-gray-20;
        &:hover {
          border: 1px solid $black;
        }

        &--is-focused {
          border-color: $black;
          box-sizing: border-box;
          box-shadow: inset 0px 0px 0px 1px $black;
        }
      }

      &__value-container {
        position: relative;
        padding: 16px 15px 16px 60px;

        &::before {
          content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2.25C8.28223 2.25 5.25 5.28223 5.25 9C5.25 10.0547 5.67773 11.2646 6.25781 12.5859C6.83789 13.9072 7.58496 15.3105 8.34375 16.6172C9.86133 19.2334 11.3906 21.4219 11.3906 21.4219L12 22.3125L12.6094 21.4219C12.6094 21.4219 14.1387 19.2334 15.6562 16.6172C16.415 15.3105 17.1621 13.9072 17.7422 12.5859C18.3223 11.2646 18.75 10.0547 18.75 9C18.75 5.28223 15.7178 2.25 12 2.25ZM12 3.75C14.9092 3.75 17.25 6.09082 17.25 9C17.25 9.60059 16.9277 10.7373 16.3828 11.9766C15.8379 13.2158 15.085 14.5898 14.3438 15.8672C13.166 17.9004 12.4336 18.9756 12 19.6172C11.5664 18.9756 10.834 17.9004 9.65625 15.8672C8.91504 14.5898 8.16211 13.2158 7.61719 11.9766C7.07227 10.7373 6.75 9.60059 6.75 9C6.75 6.09082 9.09082 3.75 12 3.75Z' fill='%23E94E0F'/%3E%3Cpath d='M9.75 9C9.75 7.75635 10.7563 6.75 12 6.75C13.2437 6.75 14.25 7.75635 14.25 9C14.25 10.2437 13.2437 11.25 12 11.25C10.7563 11.25 9.75 10.2437 9.75 9Z' fill='%23E94E0F'/%3E%3C/svg%3E");

          position: absolute;
          left: 20px;
          top: 21px;
        }
      }
    }
  }
  &__filters {
    button {
      background-color: #7783a0;
    }
    span {
      width: 20px;
      height: 20px;
    }
  }

  &__refinment-list {
    background-color: $tuna-gray-20;
    font-size: 1rem;

    &__toggle {
      width: 22px;
      height: 22px;
      font-size: 22px;
      user-select: none;
      border: 2px solid $tuna-gray-80;
      cursor: pointer;
    }

    &__count {
      color: $tuna-gray-60;
    }

    input[type='checkbox'] {
      appearance: none;
      font: inherit;
      color: $orange;
      width: 1.15em;
      height: 1.15em;
      background-color: #fff;
      border: 2px solid $orange;
      margin: 0;
      border-radius: 0.15em;
    }

    input[type='checkbox'].refined {
      background-color: $orange;
    }

    input[type='checkbox']::before {
      content: '';
      width: 100%;
      height: 100%;
      margin: 0;
      background: none;
      display: block;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input[type='checkbox'].refined::before {
      content: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 5L1.5 3L0 4.5L3.5 8L10 1.5L8.5 0L3.5 5Z' fill='white'/%3E%3C/svg%3E");
    }
  }
  &__hit {
    &-company-logo {
      width: 152px;
      height: 70px;
      margin-right: 1.5rem;
      border: 1px solid $tuna-gray-40;
    }

    &-title {
      font-size: 1.375rem;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }
    }

    &-specifications-wrapper {
      row-gap: 0.5rem;
      column-gap: 2rem;
      & > div {
        min-width: calc(50% - 1rem);
        @include media-breakpoint-up(lg) {
          min-width: auto;
        }
      }
    }

    &-description {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include media-breakpoint-up(md) {
        -webkit-line-clamp: 1;
      }
    }
  }
}

.location-input__placeholder {
  color: $tuna-gray;
}
