.candidate-overview {
  &__section {
    display: grid;
    gap: 0px 43px;
    grid-auto-flow: row;
    @include media-breakpoint-up(xl) {
      grid-template-columns: minmax(auto, 312px) auto;
      grid-template-areas:
        'search search'
        'filters hitsItem';
    }
  }

  &__search {
    grid-area: 1 / 1 / 2 / 2;
    @include media-breakpoint-up(xl) {
      grid-area: search;
      font-size: 1rem;
    }
  }

  &__filters {
    grid-area: 2 / 1 / 3 / 2;
    @include media-breakpoint-up(xl) {
      grid-area: filters;
    }
  }

  &__hitsItem {
    display: grid;
    grid-auto-flow: row;
    grid-area: 3 / 1 / 4 / 2;
    @include media-breakpoint-up(xl) {
      gap: 32px 0px;
      grid-template-areas:
        'stats'
        'hits'
        'pagination';
      grid-area: hitsItem;
      grid-template-rows: repeat(3, min-content);
    }
  }

  &__stats {
    grid-area: 1 / 1 / 2 / 2;
    @include media-breakpoint-up(xl) {
      grid-area: stats;
    }
    &--text {
      font-family: $headings-font-family;
    }
  }

  &__hits {
    grid-area: 2 / 1 / 3 / 2;
    gap: 24px;
    @include media-breakpoint-up(xl) {
      grid-area: hits;
    }
  }

  &__pagination {
    margin-top: 24px;
    grid-area: 3 / 1 / 4 / 2;
    @include media-breakpoint-up(xl) {
      grid-area: pagination;
      margin-top: 64px;
    }
  }

  &__form {
    gap: 16px;

    .react-select__value-container {
      position: relative;
      padding: 16px 15px 16px 60px;
    }

    &--react-select {
      @include media-breakpoint-up(xl) {
        max-width: 312px;
      }
    }

    .react-select__value-container::before {
      content: url("data:image/svg+xml,%3Csvg width='23' height='20' viewBox='0 0 23 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 10H16.5V12.82L18.94 14.23L18.19 15.53L15 13.69V10ZM16 8C14.6739 8 13.4021 8.52678 12.4645 9.46447C11.5268 10.4021 11 11.6739 11 13C11 14.3261 11.5268 15.5979 12.4645 16.5355C13.4021 17.4732 14.6739 18 16 18C17.3261 18 18.5979 17.4732 19.5355 16.5355C20.4732 15.5979 21 14.3261 21 13C21 11.6739 20.4732 10.4021 19.5355 9.46447C18.5979 8.52678 17.3261 8 16 8ZM16 6C17.8565 6 19.637 6.7375 20.9497 8.05025C22.2625 9.36301 23 11.1435 23 13C23 14.8565 22.2625 16.637 20.9497 17.9497C19.637 19.2625 17.8565 20 16 20C13.21 20 10.8 18.36 9.67 16H0V13C0 10.34 5.33 9 8 9C8.6 9 9.34 9.07 10.12 9.2C10.7543 8.21741 11.6249 7.40961 12.6521 6.85056C13.6794 6.29151 14.8305 5.99907 16 6ZM9 13C9 12.3 9.1 11.62 9.29 11C8.87 10.93 8.43 10.9 8 10.9C5.03 10.9 1.9 12.36 1.9 13V14.1H9.09C9.02985 13.7364 8.99974 13.3685 9 13ZM8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0ZM8 1.9C7.44305 1.9 6.9089 2.12125 6.51508 2.51508C6.12125 2.9089 5.9 3.44305 5.9 4C5.9 4.55695 6.12125 5.0911 6.51508 5.48492C6.9089 5.87875 7.44305 6.1 8 6.1C8.55695 6.1 9.0911 5.87875 9.48492 5.48492C9.87875 5.0911 10.1 4.55695 10.1 4C10.1 3.44305 9.87875 2.9089 9.48492 2.51508C9.0911 2.12125 8.55695 1.9 8 1.9Z' fill='%23E94E0F'/%3E%3C/svg%3E");

      position: absolute;
      left: 20px;
      top: 21px;
    }
  }

  &__hitCard {
    width: 100%;
    padding: 24px;
    background: $white;
    box-shadow: 0px 8px 24px rgba(48, 54, 66, 0.16);
    border-radius: 8px;
    @include media-breakpoint-up(xl) {
      width: clamp(200px, 321px, 100%);
    }

    &--title {
      font-family: $headings-font-family;
      font-size: 32px;
      line-height: 32px;
      color: $tuna-gray;
    }

    &--infoWrapper {
      gap: 16px;
    }
    &--info {
      gap: 10px;
      p {
        font-size: 16px;
        line-height: 24px;
        color: $tuna-gray;
      }
    }
    &--availability {
      font-size: 16px;
      line-height: 24px;
      color: $tuna-gray-80;
    }
  }
}
