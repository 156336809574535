.c-office-page {
  &__hero {
    background-color: $tuna-gray-10;

    &-intro {
      padding: 50px 30px;

      & > .h1,
      h1 {
        font-size: 3.25rem;
      }

      &-container {
        order: 2;

        @include media-breakpoint-up(lg) {
          order: 1;
        }
      }

      @include media-breakpoint-up(lg) {
        margin-left: 90px;
        padding: 40px 0;
      }

      &-image {
        max-width: 50%;
      }

      &-line {
        color: $tuna-gray;
        line-height: 2.5rem;
        padding: 12px 0;

        a {
          color: $tuna-gray;
          font-weight: 400;
        }

        &:before {
          content: '';
          width: 26px;
          height: 26px;
          background-repeat: no-repeat;
          display: inline-block;
        }

        &--address {
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1.625a8.948 8.948 0 0 1 8.938 8.938 8.848 8.848 0 0 1-1.801 5.362s-.244.32-.284.367L13 24.375l-6.857-8.087c-.035-.043-.28-.363-.28-.363v-.002a8.844 8.844 0 0 1-1.8-5.36A8.948 8.948 0 0 1 13 1.624Zm0 12.188a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z' fill='%23929292'/%3E%3C/svg%3E");
          }
        }

        &--phone {
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.056 2.889H7.944A1.444 1.444 0 0 0 6.5 4.333v17.334a1.444 1.444 0 0 0 1.444 1.444h10.112a1.444 1.444 0 0 0 1.444-1.444V4.333a1.444 1.444 0 0 0-1.444-1.444Zm-4.334 18.778h-1.444v-1.445h1.444v1.445Zm-5.778-2.89V4.334h10.112v14.445H7.944Z' fill='%23929292'/%3E%3C/svg%3E");
          }
        }

        &--mail {
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20 20H4a2 2 0 0 1-2-2V5.913A2 2 0 0 1 4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2ZM4 7.868V18h16V7.868L12 13.2 4 7.868ZM4.8 6l7.2 4.8L19.2 6H4.8Z' fill='%23929292'/%3E%3C/svg%3E");
          }
        }
      }

      &-button {
        padding-left: 50px;
        color: $tuna-gray;
        position: relative;
        border: 1px solid $orange;
        border-radius: 4px;
        color: $orange !important;
        font-weight: 700 !important;
        @include media-breakpoint-up(lg) {
          margin-left: 50px;
        }

        &:before {
          left: 1rem;
          top: 9px;
          content: '';
          width: 14px;
          height: 18px;
          position: absolute;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='15' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 19A29.779 29.779 0 0 1 4 15.47C2.4 13.557.5 10.711.5 8a7 7 0 0 1 11.952-4.95A6.955 6.955 0 0 1 14.5 8c0 2.712-1.9 5.558-3.5 7.47A29.78 29.78 0 0 1 7.5 19Zm0-16a5.006 5.006 0 0 0-5 5c0 1.166.527 3.185 3.035 6.186A27.918 27.918 0 0 0 7.5 16.3a28.135 28.135 0 0 0 1.966-2.11C11.973 11.183 12.5 9.164 12.5 8a5.006 5.006 0 0 0-5-5Zm0 8a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z' fill='%23E94E0F'/%3E%3C/svg%3E");
        }
      }

      &-text {
        margin-left: 1rem;
      }
    }

    &-map {
      min-height: 320px;

      &-container {
        order: 1;

        @include media-breakpoint-up(lg) {
          order: 2;
        }
      }
    }
  }

  &__wrapper {
    &--gray {
      background-color: $slate-gray-10;
      padding: 20px 0;
    }
  }

  &__divider {
    height: 1px;
    margin: 1rem 0;
    background-color: $slate-gray-10;
  }

  &__staffing-solutions {
    background-color: $white;
    padding: 2rem;

    @include media-breakpoint-up(lg) {
      margin-right: 90px;
    }
  }

  &__timetable {
    background-color: $white;
    padding: 2rem;

    @include media-breakpoint-up(lg) {
      margin-left: 90px;
    }

    &-line {
      color: $tuna-gray-80;
      font-size: 1.25rem;
      font-weight: 500;

      &--today {
        font-size: 1.125rem;
        color: $green;
      }

      &-weekday {
        border-right: $slate-gray-10 1px solid;
        min-width: 130px;
        margin: 4px 0;
      }
    }
  }
}
